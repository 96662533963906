import Image from 'next/image';
import Link from 'next/link';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faXmarkLarge,
  faChevronDown,
} from '@fortawesome/pro-light-svg-icons';
import modelNavBar from './NavBar.model';
import { useRouter } from 'next/router';

const NavBar = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [fullPage, setFullPage] = useState(false);

  const [dropdownActiveFeatures, setDropdownActive] = useState(false);
  const [dropdownActiveSolutions, setDropdownActiveSolutions] = useState(false);
  const [dropdownActiveWhyCux, setDropdownActiveWhyCux] = useState(false);

  const dropdownMenuFeatures = useRef<HTMLUListElement>(null);
  const dropdownMenuSolutions = useRef<HTMLUListElement>(null);
  const dropdownMenuWhyCux = useRef<HTMLUListElement>(null);

  const dropdownLinkFeatures = useRef<HTMLLIElement>(null);
  const dropdownLinkSolutions = useRef<HTMLLIElement>(null);
  const dropdownLinkWhyCux = useRef<HTMLLIElement>(null);
  const [ref, setRef] = useState<string | null>('');

  const pathname = useRouter().pathname;

  useEffect(() => {
    setDropdownActive(false);
    setDropdownActiveSolutions(false);
    setDropdownActiveWhyCux(false);
  }, [pathname]);

  const toggleHamburgerMenu = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const showFullPage = () => {
    setFullPage(true);
    setHamburgerOpen(false);
  };

  const [featuresDataArr, solutionsDataArr, whyCuxDataArr] = [
    modelNavBar.features,
    modelNavBar.solutions,
    modelNavBar.whyCux,
  ];

  const dropdownList = (list: any) => {
    return list.map((feature: any) => (
      <div className='dropdown__item' onClick={showFullPage} key={feature.id}>
        <Link href={feature.url}>
          <a aria-label='feature'>
            <div className='dropdown__item-container'>
              <FontAwesomeIcon
                icon={feature.icon}
                className='dropdown__item-icon'
              />
              <div className='dropdown__item-text-container'>
                <p className='dropdown__item-link'>{feature.anchor}</p>
                <p className='dropdown__item-description'>
                  {feature.description}
                </p>
              </div>
            </div>
          </a>
        </Link>
      </div>
    ));
  };

  const featuresList = featuresDataArr.map((feature) => (
    <div className='dropdown__item' onClick={showFullPage} key={feature.id}>
      <Link href={feature.url}>
        <a aria-label='feature'>
          <div className='dropdown__item-container'>
            <FontAwesomeIcon
              icon={feature.icon}
              className='dropdown__item-icon'
            />
            <div className='dropdown__item-text-container'>
              <p className='dropdown__item-link'>{feature.anchor}</p>
              <p className='dropdown__item-description'>
                {feature.description}
              </p>
            </div>
          </div>
        </a>
      </Link>
    </div>
  ));

  const solutionsList = solutionsDataArr.map((feature) => (
    <div className='dropdown__item' onClick={showFullPage} key={feature.id}>
      <Link href={feature.url}>
        <a aria-label='feature'>
          <div className='dropdown__item-container'>
            <FontAwesomeIcon
              icon={feature.icon}
              className='dropdown__item-icon'
            />
            <div className='dropdown__item-text-container'>
              <p className='dropdown__item-link'>{feature.anchor}</p>
              <p className='dropdown__item-description'>
                {feature.description}
              </p>
            </div>
          </div>
        </a>
      </Link>
    </div>
  ));

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        dropdownActiveFeatures &&
        dropdownMenuFeatures.current &&
        !dropdownMenuFeatures.current.contains(e.target)
      ) {
        setDropdownActive(true);
      }
      if (
        dropdownActiveSolutions &&
        dropdownMenuSolutions.current &&
        !dropdownMenuSolutions.current.contains(e.target)
      ) {
        setDropdownActiveSolutions(true);
      }
      if (
        dropdownActiveWhyCux &&
        dropdownMenuWhyCux.current &&
        !dropdownMenuWhyCux.current.contains(e.target)
      ) {
        setDropdownActiveWhyCux(true);
      }
    };
    const checkIfClickedMenu = (e: any) => {
      if (
        dropdownActiveFeatures &&
        dropdownLinkFeatures.current &&
        !dropdownLinkFeatures.current.contains(e.target)
      ) {
        setDropdownActive(false);
      }
      if (
        dropdownActiveSolutions &&
        dropdownLinkSolutions.current &&
        !dropdownLinkSolutions.current.contains(e.target)
      ) {
        setDropdownActiveSolutions(false);
      }
      if (
        dropdownActiveWhyCux &&
        dropdownLinkWhyCux.current &&
        !dropdownLinkWhyCux.current.contains(e.target)
      ) {
        setDropdownActiveWhyCux(false);
      }
    };

    document.addEventListener('mousemove', checkIfClickedMenu);

    return () => {
      document.removeEventListener('mouseout', checkIfClickedOutside);
    };
  }, [dropdownActiveFeatures, dropdownActiveSolutions, dropdownActiveWhyCux]);

  const showDropdownMenu = () => {
    setDropdownActive(!dropdownActiveFeatures);
  };

  const showDropdownMenuSolutions = () => {
    setDropdownActiveSolutions(!dropdownActiveSolutions);
  };

  const showDropdownMenuWhyCux = () => {
    setDropdownActiveWhyCux(!dropdownActiveWhyCux);
  };

  const router = useRouter();

  useEffect(() => {
    if (router.query.ref) {
      localStorage.setItem('ref', router.query.ref as string);
    }

    if (localStorage.getItem('ref')) {
      setRef(localStorage.getItem('ref'));
    }
  }, [router.query.ref]);

  const registerUrl = ref ? `register?ref=${ref}` : 'register';

  return (
    <div className='navbar'>
      <div className='navbar-container'>
        <Link href='/'>
          <a href={'/'}>
            <img
              src='/cux-logo.svg'
              alt='cux.io logo'
              className='navbar-container__logo'
            />
          </a>
        </Link>

        <div className='navbar__hamburger-menu hamburger-menu'>
          <FontAwesomeIcon
            icon={faBars}
            className={
              hamburgerOpen
                ? 'hamburger-menu__hamburger-icon--hidden'
                : 'hamburger-menu__hamburger-icon--active'
            }
            onClick={toggleHamburgerMenu}
          />
          <FontAwesomeIcon
            icon={faXmarkLarge}
            className={
              hamburgerOpen
                ? 'hamburger-menu__close-icon--active'
                : 'hamburger-menu__close-icon--hidden'
            }
            onClick={toggleHamburgerMenu}
          />
        </div>

        <ul
          className={
            hamburgerOpen ? 'navbar__menu--active' : 'navbar__menu menu'
          }
        >
          <li
            className='menu__item menu__item--dropdown'
            onMouseEnter={showDropdownMenu}
            ref={dropdownLinkFeatures}
          >
            <div className='menu__item--dropdown__item-container'>
              <p
                className='menu__item--dropdown__item-link'
                onClick={() => setHamburgerOpen(false)}
              >
                <Link href={`/features`}>Features</Link>
              </p>
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={showDropdownMenu}
                className={
                  dropdownActiveFeatures
                    ? 'menu__item--dropdown__arrow-icon--active'
                    : 'menu__item--dropdown__arrow-icon'
                }
              />
            </div>

            <ul
              className={classNames('menu__dropdown dropdown', {
                'dropdown--active': dropdownActiveFeatures,
              })}
              ref={dropdownMenuFeatures}
            >
              <li className='dropdown-container'>{featuresList}</li>
            </ul>
          </li>
          <li
            className='menu__item menu__item--dropdown'
            onMouseEnter={showDropdownMenuSolutions}
            ref={dropdownLinkSolutions}
          >
            <div className='menu__item--dropdown__item-container'>
              <p
                className='menu__item--dropdown__item-link'
                onClick={() => setHamburgerOpen(false)}
              >
                <Link href={`/solutions`}>Solutions</Link>
              </p>
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={showDropdownMenuSolutions}
                className={
                  dropdownActiveSolutions
                    ? 'menu__item--dropdown__arrow-icon--active'
                    : 'menu__item--dropdown__arrow-icon'
                }
              />
            </div>

            <ul
              className={classNames('menu__dropdown dropdown', {
                'dropdown--active': dropdownActiveSolutions,
              })}
              ref={dropdownMenuSolutions}
            >
              <li className='dropdown-container'>{solutionsList}</li>
            </ul>
          </li>
          <li className='menu__item' onClick={showFullPage}>
            <p className='menu__item__item-link'>
              <Link href='/case-studies'>Case Studies</Link>
            </p>
          </li>
          <li className='menu__item' onClick={showFullPage}>
            <p className='menu__item__item-link'>
              <Link href='/pricing'>Pricing</Link>
            </p>
          </li>

          <li
            className='menu__item menu__item--dropdown'
            onMouseEnter={showDropdownMenuWhyCux}
            ref={dropdownLinkWhyCux}
          >
            <div className='menu__item--dropdown__item-container'>
              <p
                className='menu__item--dropdown__item-link'
                onClick={() => setHamburgerOpen(false)}
              >
                <Link href={`/about/`}>About Us</Link>
              </p>
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={showDropdownMenuWhyCux}
                className={
                  dropdownActiveWhyCux
                    ? 'menu__item--dropdown__arrow-icon--active'
                    : 'menu__item--dropdown__arrow-icon'
                }
              />
            </div>

            <ul
              className={classNames('menu__dropdown dropdown', {
                'dropdown--active': dropdownActiveWhyCux,
              })}
              ref={dropdownMenuWhyCux}
            >
              <li className='dropdown-container'>
                {dropdownList(whyCuxDataArr)}
              </li>
            </ul>
          </li>

          <li className='menu__item' onClick={showFullPage}>
            <p className='menu__item__item-link'>
              <Link href='/blog'>Blog</Link>
            </p>
          </li>
          <li className='menu__item menu__item--button'>
            <button
              className='menu__item menu__item--button'
              id='start_trial_navbar'
            >
              <a
                href={`https://app.cux.io/${registerUrl}`}
                className='menu__item menu__item--button-text'
                target='_blank'
                rel='noopener noreferrer'
              >
                Start free trial
              </a>
            </button>
          </li>
          <li className='menu__item menu__item--login'>
            <button
              className='menu__item menu__item--login-button'
              id='login_navbar'
            >
              <a
                href='https://app.cux.io/login'
                className='menu__item menu__item--login-button-text'
                target='_blank'
                rel='noopener noreferrer'
              >
                Login
              </a>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
