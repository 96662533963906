import Link from 'next/link';

const Breadcrumbs = ({ data }: any) => {
  const pathnameToArr = data.split('/').filter(Boolean);

  const breadcrumbs = pathnameToArr.map(
    (item: string, index: number, array: number[]) => (
      <Link
        href={`/${index === 0 ? `${item}` : `${array[0]}/${array[1]}`}`}
        key={index}
        passHref
      >
        <a className='breadcrumbs-link'>{item.replaceAll('-', ' ')}</a>
      </Link>
    )
  );

  return (
    <div className='breadcrumbs'>
      <Link href={`/`} passHref>
        <a className='breadcrumbs-link'>Home</a>
      </Link>
      {breadcrumbs}
    </div>
  );
};

export default Breadcrumbs;
