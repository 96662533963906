import Link from 'next/link';

interface Props {
  bannerData: {
    copy: string;
    url: string;
    cta: string;
    color: string;
  };
}

const SuccessBanner = (props: Props) => {
  const { copy, url, cta, color } = props.bannerData;

  return (
    <div
      className='successBanner'
      style={{
        backgroundColor: color === 'Pink' ? '#fc2a65' : '#09d2d4',
      }}
    >
      <div className='successBanner-container'>
        <p className='successBanner__text'>{copy}</p>

        <Link href={`${url}`} passHref>
          <button className='successBanner__button'>{cta}</button>
        </Link>
      </div>
    </div>
  );
};

export default SuccessBanner;
