import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import ProgressBar from '@badrap/bar-of-progress';
import Router from 'next/router';
import Head from 'next/head';
import Footer from '../src/components/Footer/Footer';
import BottomMenu from '../src/components/BottomMenu/BottomMenu';
import SuccessBanner from '../src/components/SuccessBanner/SuccessBanner';
import NavBar from '../src/components/NavBar/NavBar';
import Newsletter from '../src/components/Newsletter/Newsletter';
import ToTheTopButton from '../src/components/ToTheTopButton/ToTheTopButton';
import FeaturesBookDemo from '../src/components/FeaturesBookDemo/FeaturesBookDemo';
import { useRouter } from 'next/router';
import NewsletterPage from '../src/components/NewsletterPage/NewsletterPage';
import Cookies from '../src/components/Cookies/Cookies';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import axios from 'axios';

const progress = new ProgressBar({
  size: 3,
  color: '#fd3957',
  className: 'bar-of-progress',
  delay: 100,
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const featuresRoute = router.pathname.slice(1, 9);
  const solutionsRoute = router.pathname.slice(1, 10);
  const newsletterPageRoute = router.pathname.slice(1);
  const [bannerData, setBannerData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.NEXT_PUBLIC_CUX_CONTENT_URL}/api/website-banners/n19y2x3neekdyslxjtqatwlk`
        );
        setBannerData(res.data.data);
        return res.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (router.query['ref']) {
      localStorage.setItem('ref', router.query['ref'] as string);
    }
  }, [router.query]);

  return (
    <>
      <Head>
        <title>cux.io - The Digital Experience Analytics Tool for e-commerce, insurtech, fintech. For you</title>

        <link rel='icon' href='/favicon.ico' />
      </Head>

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K2WZ72Q"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />

      <header>
        <nav>
          {/* @ts-ignore */}
          {bannerData && <SuccessBanner bannerData={bannerData} />}
          <NavBar />
        </nav>
      </header>

      <Component {...pageProps} />
      <ToTheTopButton />

      <Cookies />

      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied',
            });
            gtag('js', new Date());
            gtag('config', 'GTM-K2WZ72Q', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      <Script
        id='gtm'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K2WZ72Q');`,
        }}
      />

      <footer>
        {featuresRoute === 'features' ||
        solutionsRoute === 'solutions' ||
        solutionsRoute === 'pricing' ||
        solutionsRoute === 'compare' ? (
          <FeaturesBookDemo />
        ) : <Newsletter /> && newsletterPageRoute === 'newsletter' ? (
          <NewsletterPage />
        ) : (
          <Newsletter />
        )}
        <BottomMenu />
        <Footer />
      </footer>
    </>
  );
}

export default MyApp;
