import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';

const ToTheTopButton = () => {
  const showBelow = 200;

  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window['scrollTo']({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  });

  return (
    <button
      className={classNames('to-the-top-button', {
        'to-the-top-button--active': show,
      })}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faChevronUp} className='to-the-top-button__icon' />
    </button>
  );
};

export default ToTheTopButton;
