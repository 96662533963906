import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCheck } from '@fortawesome/pro-light-svg-icons';
import { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useRouter } from 'next/router';

const NewsletterPage = () => {
  const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const router = useRouter();
  const pathname = router.pathname;

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [checked, setChecked] = useState<null | Boolean>(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [checkedError, setCheckedError] = useState(false);

  const agreeToTheTerms = () => {
    setChecked(true);

    if (checked) {
      setChecked(false);
    }
  };

  const newsletterSubmit = (e: SyntheticEvent) => {
    if (
      !regexEmail.test(email) ||
      firstName.length <= 1 ||
      lastName.length <= 1 ||
      company.length <= 1 ||
      phoneNumber.length ||
      !checked
    ) {
      e.preventDefault();
    }

    setFirstNameError(firstName.length <= 1);

    setFirstNameError(!(firstName.length > 1));

    setLastNameError(lastName.length <= 1);

    setLastNameError(!(lastName.length > 1));

    setCompanyError(company.length <= 1);

    setCompanyError(!(company.length > 1));

    setCheckedError(!!checked);

    setCheckedError(!checked);

    setEmailError(!!regexEmail.test(email));

    setEmailError(!regexEmail.test(email));
  };

  return (
    <section className='newsletter-page'>
      <div className='newsletter-page__container'>
        <Breadcrumbs data={pathname} />
        <div className='newsletter-page__text-container'>
          <p className='newsletter-page__text'>
            Not subscribed yet?
            <br />
            <span className='newsletter-page__text--bold'>
              {' '}
              Subscribe our CUXletter!
            </span>
          </p>
        </div>
        <div className='newsletter-page-form__container'>
          <form
            action='https://app.getresponse.com/add_subscriber.html'
            id='newsletter'
            acceptCharset='utf-8'
            method='post'
            className='newsletter-page-form'
            noValidate
          >
            <div className='newsletter-page-form__required-input-fields'>
              <input
                id='newsletter-email'
                name='email'
                type='email'
                placeholder='E-mail address *'
                className={classNames(
                  'newsletter-page-form__input required-input-fields__input',
                  { 'newsletter-page-form__input--error': emailError }
                )}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                id='newsletter-first_name'
                name='first_name'
                type='text'
                placeholder='First Name *'
                className={classNames(
                  'newsletter-page-form__input required-input-fields__input',
                  { 'newsletter-page-form__input--error': firstNameError }
                )}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='newsletter-page-form__input-fields'>
              <input
                id='newsletter-last_name'
                name='last_name'
                type='text'
                placeholder='Last name *'
                className={classNames(
                  'newsletter-page-form__input required-input-fields__input',
                  { 'newsletter-page-form__input--error': lastNameError }
                )}
                onChange={(e) => setLastName(e.target.value)}
              />
              <input
                id='newsletter-custom_company'
                name='custom_company'
                type='text'
                placeholder='Company *'
                className={classNames(
                  'newsletter-page-form__input required-input-fields__input',
                  { 'newsletter-page-form__input--error': companyError }
                )}
                onChange={(e) => setCompany(e.target.value)}
              />
              <input
                id='newsletter-custom_position'
                name='custom_position'
                type='text'
                placeholder='Position'
                className='newsletter-page-form__input '
                onChange={(e) => setPosition(e.target.value)}
              />
              <input
                id='custom_phone'
                name='custom_phone'
                type='text'
                placeholder='Phone number'
                className='newsletter-page-form__input '
                hidden={true}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <input
                id='custom_phone_number'
                name='custom_phone_number'
                type='text'
                placeholder='Second phone number'
                className='newsletter-page-form__input newsletter-page-form__input-second-phone-number'
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className='newsletter-page-form__marketing-offers marketing-offers'>
              <div
                className={classNames('marketing-offers__checkbox-container', {
                  'marketing-offers__checkbox-container--error': checkedError,
                })}
                onClick={agreeToTheTerms}
              >
                <input
                  id='webform_consent#hUOs_0'
                  name='webform[consent#hUOs-ver#z6ob]'
                  type='checkbox'
                  className='marketing-offers__checkbox'
                />
                <FontAwesomeIcon
                  icon={faCheck}
                  className={classNames('marketing-offers__checkbox-icon', {
                    'marketing-offers__checkbox-icon--active': checked,
                  })}
                />
              </div>

              <div
                className={classNames('marketing-offers__text', {
                  'marketing-offers__text--error': checkedError,
                })}
              >
                <p
                  onClick={agreeToTheTerms}
                  className={classNames('marketing-offers__text', {
                    'marketing-offers__text--error': checkedError,
                  })}
                >
                  I agree for marketing communication & offers. I accept that
                  provided data will be processed by{' '}
                  <Link
                    href='https://www.getresponse.com/legal/privacy'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    GetResponse S. A.
                  </Link>
                  <br />
                  For more information, check out our 
                </p>
                <span
                  className={classNames('marketing-offers__text', {
                    'marketing-offers__text--error': checkedError,
                  })}
                >
                  <Link href={`/legal/privacy-policy`}>privacy policy.</Link>
                </span>
              </div>
            </div>
            <div className='newsletter-page-form__submit-container'>
              <input
                id='newsletter-subscribe_button'
                type='submit'
                value='subscribe'
                className='newsletter-page-form__submit-input'
                onClick={newsletterSubmit}
              />
            </div>
            <input
              type='hidden'
              name='thankyou_url'
              value='https://cux.io/subscribed/'
            />
            <input type='hidden' name='campaign_token' value='zntlV' />
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewsletterPage;
