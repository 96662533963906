import classNames from 'classnames';
import styles from './Cookies.module.scss';
import CookieConsent from 'react-cookie-consent';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Cookies = () => {

  const router = useRouter();

  const handleRouteChange = (url: string) => {
    // @ts-ignore
    const { gtag } = window;
    if (!gtag) return;
    gtag('event', 'page_view', {
      page_path: url,
    });
  };

  const hasConsent = () => {

    let decodedCookie: string | string[] | undefined = decodeURIComponent(
      document.cookie
    );

    decodedCookie = decodedCookie.split(';');
    decodedCookie = decodedCookie.find((cookie) => {
      return cookie.substring(0, 13) === 'CookieConsent';
    });

    if (!decodedCookie) {
      return false;
    }

    return decodedCookie.substring(14, 18) === 'true';
  };

  const grant = () => {
    return send(consentType.granted);
  }

  const deny = () => {
    return send(consentType.denied);
  }

  enum consentType {
    granted = 'granted',
    denied = 'denied',
  }

  let timer: NodeJS.Timeout;
  const send = (type: consentType) => {
    // @ts-ignore
    const { gtag } = window;
    if (!gtag) {
      timer = setTimeout(() => send(type), 50);
      return;
    } else {
      clearTimeout(timer);
    }
    gtag('consent', 'update', {
      ad_storage: type,
      analytics_storage: type,
    });
  };

  const handleConsent = () => {
    grant();
  }

  useEffect(() => {
    return hasConsent() ? grant() : deny();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <CookieConsent
      location='none'
      overlayClasses={styles.overlay}
      containerClasses={styles.container}
      enableDeclineButton
      buttonText='Accept'
      declineButtonText='Reject'
      buttonClasses={styles.btn}
      declineButtonClasses={classNames([styles.btn, styles.decline])}
      buttonWrapperClasses={styles.actions}
      onAccept={() => {
        handleConsent();
      }}
      disableStyles
    >
      <p className={styles.title}>We value your privacy</p>
      <p className={styles.content}>
        We use cookies to enhance your browsing experience, serve personalized
        content and analyze our traffic. By clicking &quot;Accept&quot;, you
        consent to our use of cookies.
      </p>
    </CookieConsent>
  );
};

export default Cookies;
