import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseyeArrow,
  faCartShoppingFast,
  faChartLineUp,
  faCircleHeart,
  faClockRotateLeft,
  faFilter,
  faHandshake,
  faImage,
  faPersonCircleQuestion,
  faRadar,
  faRankingStar,
  faSensorTriangleExclamation,
  faSitemap,
  faUserRobot,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';

const modelNavBar = {
  features: [
    {
      id: 1,
      anchor: 'User Behaviour Analysis',
      description: 'Analyze user behaviors to eliminate conversion inhibits',
      url: '/features/user-behaviour-analysis',
      icon: faRadar,
    },
    {
      id: 2,
      anchor: 'Conversion Waterfalls',
      description: 'Spot missed opportunities for conversion on user journey',
      url: '/features/conversion-waterfalls',
      icon: faFilter,
    },
    {
      id: 3,
      anchor: 'Heatmaps',
      description:
        "Visualize user's frustrations for single and group pages, traffic sources and SPAs",
      url: '/features/heatmaps',
      icon: faImage,
    },
    {
      id: 4,
      anchor: 'Visit Recordings',
      description: 'Watch whole visits (even with multiple tabs open)',
      url: '/features/visit-recordings',
      icon: faVideo,
    },
    {
      id: 5,
      anchor: 'Goal-oriented Analysis',
      description: 'Work only with data relevant to your business',
      url: '/features/goal-oriented-analysis',
      icon: faBullseyeArrow,
    },
    {
      id: 6,
      anchor: 'Pre-analysis & Alerts',
      description:
        'Get personalized alerts about any significant changes in your projects',
      url: '/features/pre-analysis-alerts',
      icon: faSensorTriangleExclamation,
    },
    {
      id: 7,
      anchor: 'Auto-capture Events',
      description: 'Collect data automatically without any dev/IT skills',
      url: '/features/auto-capture-events',
      icon: faUserRobot,
    },
    {
      id: 8,
      anchor: 'Retroactive Analysis',
      description: 'Create and modify customer journeys using historical data',
      url: '/features/retroactive-analysis',
      icon: faClockRotateLeft,
    },
  ],
  solutions: [
    {
      id: 1,
      anchor: 'User Behavior Analytics',
      description:
        'Spot behavioral patterns and turn frustrated users into happy customers',
      url: '/solutions/user-behaviour-analysis',
      icon: faRadar,
    },
    {
      id: 2,
      anchor: 'E-commerce Analytics',
      description: "Increase revenue with customers' behavior insights",
      url: '/solutions/e-commerce-analytics',
      icon: faCartShoppingFast,
    },
    {
      id: 3,
      anchor: 'Customer Journey Analytics',
      description: 'Map customer journey based on data, not gut feeling',
      url: '/solutions/customer-journey-analytics',
      icon: faPersonCircleQuestion,
    },
    {
      id: 4,
      anchor: 'Conversion Optimization',
      description: 'Improve user experience to immediately increase conversion',
      url: '/solutions/conversion-optimization',
      icon: faChartLineUp,
    },
    {
      id: 5,
      anchor: 'Product Optimization',
      description: 'Find bottlenecks on your website in minutes',
      url: '/solutions/product-optimization',
      icon: faSitemap,
    },
    {
      id: 6,
      anchor: 'Marketing Optimization',
      description: 'Introduce smart data marketing',
      url: '/solutions/marketing-optimization',
      icon: faRankingStar,
    },
  ],
  whyCux: [
    {
      id: 1,
      anchor: 'Why CUX?',
      description: 'They’ve already bet on us – you should too!',
      url: '/about/why-cux',
      icon: faCircleHeart,
    },
    {
      id: 2,
      anchor: 'Become a partner',
      description: 'Certification Program for Digital & Innovative Agencies',
      url: '/about/become-partner',
      icon: faHandshake,
    },
  ],
};

export default modelNavBar;
