import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

const model = {
  social: [
    {
      anchor: 'Facebook',
      url: 'https://www.facebook.com/cuxio',
      icon: faFacebookF,
      isExternal: true,
    },
    {
      anchor: 'LinkedIn',
      url: 'https://pl.linkedin.com/company/cuxio',
      icon: faLinkedinIn,
      isExternal: true,
    },
    {
      anchor: 'Twitter',
      url: 'https://twitter.com/cux_io',
      icon: faTwitter,
      isExternal: true,
    },
    {
      anchor: 'YouTube',
      url: 'https://www.youtube.com/channel/UCN8A1Iw4ZitdW3136q_Hc5Q',
      icon: faYoutube,
      isExternal: true,
    },
  ],
  company: [
    {
      anchor: 'About',
      url: '/about/',
      isExternal: false,
    },
    {
      anchor: 'Contact',
      url: '/contact/',
      isExternal: false,
    },
    {
      anchor: 'Career',
      url: '/jobs/',
      isExternal: false,
    },
  ],
  product: [
    {
      anchor: 'Knowledge base',
      url: 'https://help.cux.io/support/solutions',
      isExternal: true,
    },
    {
      anchor: 'Compare',
      url: '/compare/',
      isExternal: false,
    },
    {
      anchor: 'Free trial',
      url: 'https://app.cux.io/register',
      isExternal: false,
    },
    {
      anchor: 'Book a demo',
      url: '/request-demo/',
      isExternal: false,
    },
    {
      anchor: 'Legal',
      url: '/legal/',
      isExternal: false,
    },
    {
      anchor: 'Status',
      url: 'https://status.cux.io/',
      isExternal: true,
    },
    {
      anchor: 'G2 Crowd',
      url: 'https://www.g2.com/products/cux-io/reviews',
      isExternal: true,
    },
  ],

  solutions: [
    {
      anchor: 'Customer Journey Analytics',
      url: '/customer-journey-analytics-software/',
      isExternal: false,
    },
    {
      anchor: 'Customer Journey & Customer Experience Mapping',
      url: '/customer-journey-mapping-tools/',
      isExternal: false,
    },
    {
      anchor: 'Customer Experience Optimization',
      url: '/customer-experience-optimization/',
      isExternal: false,
    },
    {
      anchor: 'User Behavior Analytics',
      url: '/user-behavior-analytics-tools/',
      isExternal: false,
    },
    {
      anchor: 'Conversion Rate Optimization',
      url: '/conversion-rate-optimization-tools/',
      isExternal: false,
    },
    {
      anchor: 'Form Analytics',
      url: '/form-analytics/',
      isExternal: false,
    },
    {
      anchor: 'Website Usability Testing',
      url: '/website-usability-testing-tools/',
      isExternal: false,
    },
    {
      anchor: 'Website Tracking',
      url: '/website-tracking/',
      isExternal: false,
    },
  ],
};

export default model;
