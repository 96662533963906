import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCheck } from '@fortawesome/pro-light-svg-icons';
import { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

const FeaturesBookDemo = () => {
  const [ref, setRef] = useState<string | null>('');

  const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [checked, setChecked] = useState<null | Boolean>(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [checkedError, setCheckedError] = useState(false);

  const agreeToTheTerms = () => {
    setChecked(true);

    if (checked) {
      setChecked(false);
    }
  };

  const newsletterSubmit = (e: SyntheticEvent) => {
    if (
      !regexEmail.test(email) ||
      firstName.length <= 1 ||
      lastName.length <= 1 ||
      company.length <= 1 ||
      phoneNumber.length ||
      !checked
    ) {
      e.preventDefault();
    }
    if (firstName.length <= 1) {
      setFirstNameError(true);
    }
    if (firstName.length > 1) {
      setFirstNameError(false);
    }
    if (lastName.length <= 1) {
      setLastNameError(true);
    }
    if (lastName.length > 1) {
      setLastNameError(false);
    }
    if (company.length <= 1) {
      setCompanyError(true);
    }
    if (company.length > 1) {
      setCompanyError(false);
    }
    if (!checked) {
      setCheckedError(true);
    }
    if (checked) {
      setCheckedError(false);
    }
    if (!regexEmail.test(email)) {
      setEmailError(true);
    }
    if (regexEmail.test(email)) {
      setEmailError(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('ref')) {
      setRef(localStorage.getItem('ref'));
    }
  }, []);

  const referral = ref ? ref : '';

  return (
    <section className='features-booking'>
      <div className='features-booking__container'>
        <div className='features-booking__text-container'>
          <p className='features-booking__text'>
            Not sure yet?
            <br />
            <span className='features-booking__text--bold'>
              {' '}
              Book a FREE DEMO
            </span>
            !
          </p>
        </div>
        <div className='features-booking-form__container'>
          <form
            action='https://app.getresponse.com/add_subscriber.html'
            id='newsletter'
            acceptCharset='utf-8'
            method='post'
            className='features-booking-form'
            noValidate
          >
            <div className='features-booking-form__required-input-fields'>
              <input
                id='demo-email'
                name='email'
                type='email'
                placeholder='E-mail address *'
                className={classNames(
                  'features-booking-form__input required-input-fields__input',
                  { 'features-booking-form__input--error': emailError }
                )}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                id='demo-first_name'
                name='first_name'
                type='text'
                placeholder='First Name *'
                className={classNames(
                  'features-booking-form__input required-input-fields__input',
                  { 'features-booking-form__input--error': firstNameError }
                )}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='features-booking-form__input-fields'>
              <input
                id='demo-last_name'
                name='last_name'
                type='text'
                placeholder='Last name *'
                className={classNames(
                  'features-booking-form__input required-input-fields__input',
                  { 'features-booking-form__input--error': lastNameError }
                )}
                onChange={(e) => setLastName(e.target.value)}
              />
              <input
                id='demo-custom_company'
                name='custom_company'
                type='text'
                placeholder='Company *'
                className={classNames(
                  'features-booking-form__input required-input-fields__input',
                  { 'features-booking-form__input--error': companyError }
                )}
                onChange={(e) => setCompany(e.target.value)}
              />
              <input
                id='demo-custom_position'
                name='custom_position'
                type='text'
                placeholder='Position'
                className='features-booking-form__input '
                onChange={(e) => setPosition(e.target.value)}
              />
              <input
                id='custom_phone'
                name='custom_phone'
                type='text'
                placeholder='Phone number'
                className='features-booking-form__input '
                hidden={true}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <input
                id='custom_phone_number'
                name='custom_phone_number'
                type='text'
                placeholder='Second phone number'
                className='features-booking-form__input features-booking-form__input-second-phone-number'
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className='features-booking-form__marketing-offers marketing-offers'>
              <div
                className={classNames('marketing-offers__checkbox-container', {
                  'marketing-offers__checkbox-container--error': checkedError,
                })}
                onClick={agreeToTheTerms}
              >
                <input
                  id='webform_consent#hUOs_0'
                  name='webform[consent#hUOs-ver#z6ob]'
                  type='checkbox'
                  className='marketing-offers__checkbox'
                />
                <FontAwesomeIcon
                  icon={faCheck}
                  className={classNames('marketing-offers__checkbox-icon', {
                    'marketing-offers__checkbox-icon--active': checked,
                  })}
                />
              </div>

              <div
                className={classNames('marketing-offers__text', {
                  'marketing-offers__text--error': checkedError,
                })}
              >
                <p
                  onClick={agreeToTheTerms}
                  className={classNames('marketing-offers__text', {
                    'marketing-offers__text--error': checkedError,
                  })}
                >
                  You may unsubscribe from these communications at any time. For
                  more information, check out our 
                </p>
                <span
                  className={classNames('marketing-offers__text', {
                    'marketing-offers__text--error': checkedError,
                  })}
                >
                  <Link href={`/legal/privacy-policy`}>privacy policy.</Link>
                </span>
              </div>
            </div>
            <div className='features-booking-form__submit-container'>
              <input
                type='submit'
                value='book a free demo'
                className='features-booking-form__submit-input'
                id='demo-book_demo_form_features_solutions'
                onClick={newsletterSubmit}
              />
            </div>
            <input
              type='hidden'
              name='thankyou_url'
              value='https://cux.io/book-demo'
            />
            <input type='hidden' name='campaign_token' value='zRJa9' />
            <input name='custom_referral' type='hidden' value={`${referral}`} />
          </form>
        </div>
      </div>
    </section>
  );
};

export default FeaturesBookDemo;
