import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';

const Footer = () => {
  const date = new Date();

  return (
    <div className='footer'>
      <div className='footer__container'>
        <p className='footer__text'>
          made with{' '}
          <FontAwesomeIcon icon={faHeart} className='statistics__text-icon' />{' '}
          in Wrocław
        </p>
        <p className='footer__text'>{`© cux.io ${date.getFullYear()}`}</p>
      </div>
    </div>
  );
};

export default Footer;
